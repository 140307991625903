* {
  box-sizing: border-box;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.levelInfo {
  margin: 1.5em 0px;
}
.levelText {
  color: white !important;
  font-size: 1rem;
}
.star{
  fill: white;
  transition: fill 4s 0.5s ease;
}
.star-appear-done {		  
  stroke: black;
  animation: dash 10s ease-in forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  fill: gold;
}
.starInactive-appear-done {		  
  stroke: black;
  animation: dash 10s ease-in forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  fill: white;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Cursor {
  color: black;
  font-weight: bold;
  text-decoration: none;
  animation-name: blinker;
  animation-duration: 0.7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.App-link {
  color: #61dafb;
}
@keyframes blinker {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Card{
background: -moz-linear-gradient(top, #3f4c6b 0%, #3f4c6b 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #3f4c6b 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #3f4c6b 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}